/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets 600px and 1024px) */
@media screen and (min-width: 600px) and (max-width: 1024px) {
}

/* Desktops (Desktop 1024px) */
@media screen and (min-width: 768px) {
    .container{
        display:flex;
        flex-direction:row;
        height:100vh;
    }
    .drawerContainer{
        width: 20vw;
        background-color:#0f3c50;
    }
    .logoContainer{
        text-align: center;
        margin-bottom: 50px;
    }
    .btn{
        padding: 13px 0;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        color: #CACACA;
    }
    .btn:hover{
        background-color: #2E789B;
        color: white;
        /* font-weight: bold; */
        
    }

    .section{
        font-size: 13px;
        color:white; 
        margin: 15px 20px;
        /* font-weight:bold; */
    }
    .logout{
        display: flex;
        flex-direction: row;
        color:#FF1919;
        /* padding: 18px 20px; */
        padding-left: 20px;
        padding-top: 16px;
        padding-bottom: 11px;
    }

    .bodyCanvasContainer{
        display: flex;
        flex-direction: row;
        width: 85vw;
        /* width:100%; */
        /* border:4px solid blue */
        
    }

}
