/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets 600px and 1024px) */
@media screen and (min-width: 600px) and (max-width: 1024px) {
}

/* Desktops (Desktop 1024px) */
@media screen and (min-width: 768px) {
    .level{
        display: flex;
        align-items: center;
        /* width: 60px; */
        height: 50px;
        border: 0.5px solid black;
        overflow: hidden;
        justify-content: center;
        font-size: 0.8rem;
    }
    .desc{
        display: flex;
        text-align: center;
        /* width: 70vw; */
        height: 50px;
        border: 0.01px solid black;
        border-left: 0;
        border-top: 0.01px solid black;
        border-bottom: 0.01px solid black;
        border-right: 0;
        align-items: center;
        justify-content: center;
        align-content: center;
        font-size: 0.8rem;
    }
    .bg7{
        background-color: #00b050;
        color:white
    }
    .bg6{
        background-color: #548135;
        color:white
    }
    .bg5{
        background-color: #c5e0b3;
        color:white
    }
    .bg4{
        background-color: #ffd965;
        color:white
    }
    .bg3{
        background-color: #ffc000;
        color:white
    }
    .bg2{
        background-color: #ed7d31;
        color:white
    }
    .bg1{
        background-color: #ff0000;
        color:white
    }

    .result{
        margin-left: 5px;
        font-weight: bold;
        font-size: 15px;
    }

    .resultColor7{
        color: #00b050;

    }
    .resultColor6{
        color: #548135;

    }
    .resultColor5{
        color: #c5e0b3;

    }
    .resultColor4{
        color: #ffd965;

    }
    .resultColor3{
        color: #ffc000;

    }
    .resultColor2{
        color: #ed7d31;

    }
    .resultColor1{
        color: #ff0000;

    }
}
