table{
  /* border-radius: 8px; */
  /* overflow: hidden; */
}

/* th, td {
    border: 1.7px solid #787878;
    border-collapse: collapse;
  } */
th {
    /* background-color: #F8FDFF; */
    color:black
  }
td {
    background-color: white;
    color:black
  }

.table_evaluation {
    background-color: white;
    min-width: 100%;

}

.contain {
    background-color: white;
}

