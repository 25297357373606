/* Smartphones (Portrait phones, 600px and down) */
@media screen and (max-width: 600px) {
}

/* Tablets (Portrait tablets 600px and 1024px) */
@media screen and (min-width: 600px) and (max-width: 1024px) {
}

/* Desktops (Desktop 1024px) */
@media screen and (min-width: 768px) {
    .drawerContainer{
        width: 15vw;
        background-color:#0f3c50;
        /* position: fixed; */
        /* top: 0;
        left: 0;
        bottom: 0; */
    }
    .logoContainer{
        text-align: center;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        /* border: 1px solid white; */
        align-items: center;
        margin-top: 20px;
    }
    .section{
        font-size: 13px;
        color:white; 
        margin: 15px 20px;
        /* font-weight:bold; */
    }
    .logout{
        display: flex;
        flex-direction: row;
        color:#FF1919;
        /* padding: 18px 20px; */
        padding-left: 20px;
        padding-top: 16px;
        padding-bottom: 11px;
    }
    .logout:hover{
        background-color: #FF1C1C;
        color:white
    }
    .textBtn{
        margin-left: 8px;
        padding-top: 4px;
    }
    .textBtn:hover{
        color: white;
        margin-left: 8px;
        /* font-weight: bold; */
    }
}